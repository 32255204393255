<template>
	<div v-if="page" class="call">
		<div class="call-action-bar">
			<div class="call-actions">
				<router-link :to="{ name: 'app.paging' }" class="call-action">
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'arrow-to-left']"
							aria-hidden="true"
						/>
					</span>
					Back
				</router-link>
			</div>
			<div class="call-actions">
				<button class="call-action" @click.prevent="onDismissPage">
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
					</span>
					Dismiss
				</button>
				<button
					v-if="isUrgent"
					class="call-action"
					@click.prevent="panels.reassign = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'people-carry']"
							aria-hidden="true"
						/>
					</span>
					Reassign
				</button>
				<button
					class="call-action"
					@click.prevent="panels.notes.create = true"
				>
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'sticky-note']"
							aria-hidden="true"
						/>
					</span>
					Add Note
				</button>
				<button class="call-action" @click.prevent="exportToPdf">
					<span class="call-action-icon">
						<font-awesome-icon
							fixed-width
							:icon="['far', 'file-pdf']"
							aria-hidden="true"
						/>
					</span>
					Export
				</button>
			</div>
		</div>
		<div class="call-container">
			<div class="flex px-3 py-2 w-100">
				<call-tabs :active="tab" @activate="activateTab" />
			</div>
			<div class="call-columns">
				<div class="call-column call-info">
					<div v-if="tab === 'message'" class="call-info-block">
						<div class="call-info-card">
							<h3
								class="font-nunito font-semibold w-100 text-lg py-1 text-gray-800"
							>
								Page Message
							</h3>
							<div
								class="flex flex-col items-center justify-start py-4 w-1/3"
							>
								<font-awesome-icon
									class="text-3xl mb-2"
									:icon="['fad', 'user-circle']"
									style="--fa-primary-color: #6915ef; --fa-secondary-color: #a0aec0;"
								></font-awesome-icon>
								<span
									v-if="page.patient_name"
									class="text-center text-sm font-semibold text-gray-700"
								>
									{{ page.patient_name }}
								</span>
								<span
									v-else-if="page.callback_number"
									class="text-center text-sm font-semibold text-gray-700"
								>
									{{
										page.callback_number | formatPhoneNumber
									}}
								</span>
								<span
									v-if="
										page.patient_name &&
											page.callback_number
									"
									class="text-center text-xs font-semibold text-gray-700"
								>
									{{
										page.callback_number | formatPhoneNumber
									}}
								</span>
							</div>
							<div class="w-2/3 pl-2">
								<div class="flex items-center mt-4 pb-0">
									<div
										style="max-height: 200px; overflow-y: auto; outline: none;"
									>
										<div
											v-if="page.patient_message"
											id="call-message"
											contenteditable="false"
											class="w-100 p-2 px-2 text-gray-800 break-normal"
											style="white-space: pre-wrap; margin: 0; padding: 0;"
										>
											{{ page.patient_message }}
										</div>
									</div>
								</div>
								<div class="w-100 pt-2">
									<div
										class="flex items-center pt-4 px-2 pb-0"
									>
										<div
											class="text-gray-700 text-sm font-medium ml-auto"
										>
											{{ page.created_at | toDateTime }}
										</div>
									</div>

									<div
										class="flex items-center pt-4 px-2 pb-0"
									>
										<div class="chip-set">
											<chip
												v-if="isComplete"
												condensed
												:icon="['fal', 'check']"
												class="bg-green-100 text-green-500 border-green-200"
											>
												Complete
											</chip>

											<chip
												v-if="page.is_urgent"
												condensed
												:icon="['fal', 'clock']"
												class="bg-orange-100 text-orange-500 border-orange-200"
											>
												Urgent
											</chip>

											<chip v-else condensed>
												Non-Urgent
											</chip>
											<chip
												v-for="type in page.types"
												:key="type.id"
												condensed
											>
												{{ type.name }}
											</chip>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="call-info-card">
							<h3
								class="font-nunito font-semibold w-100 text-lg py-1 text-gray-800"
							>
								Page Notes
							</h3>
							<div
								v-for="(note, $index) in notes"
								:key="note.id"
								class="flex p-2 font-roboto w-100"
								:class="{
									'border-b': $index + 1 != notes.length,
								}"
							>
								<div
									class="flex flex-col items-center justify-center py-2 w-1/4"
								>
									<font-awesome-icon
										class="text-3xl mb-2"
										:icon="['fad', 'user-md-chat']"
										style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
									></font-awesome-icon>
									<span
										class="text-sm text-gray-800 text-center whitespace-no-wrap"
									>
										{{ note.user.first_name }}
										{{ note.user.last_name }}
									</span>
								</div>

								<div class="pl-3 w-4/5 flex flex-col">
									<div
										class="flex items-center w-100 pt-2 px-2 pb-0"
									>
										{{ note.content }}
									</div>

									<div
										class="flex items-center justify-start mt-auto mb-2 pt-2 px-2 pb-0 text-gray-600 text-sm font-medium"
									>
										{{ note.created_at | toDateTime }}
									</div>
								</div>
								<div
									v-if="canEditNote(note)"
									class="pl-3 w-2/5 flex flex-row justify-end items-center"
								>
									<button
										type="button"
										class="btn btn-sm w-auto mr-1"
										data-tooltip="Edit Note"
										data-position="bottom"
										aria-label="Edit Note"
										@click.prevent="
											onEditNoteClick(page, note)
										"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											class="btn-icon"
											:icon="['fal', 'pencil']"
										/>
									</button>
									<button
										type="button"
										class="btn btn-sm"
										data-tooltip="Delete Note"
										data-position="bottom"
										@click.prevent="
											onDeleteNoteClick(page, note)
										"
									>
										<div class="btn-ripple"></div>
										<font-awesome-icon
											fixed-width
											class="btn-icon"
											:icon="['fal', 'times-circle']"
										/>
									</button>
								</div>
								<div
									v-else
									class="pl-3 w-2/5 flex flex-row justify-end items-center"
								/>
							</div>
							<div v-if="notes.length === 0">
								<span class="pt-2 text-gray-600 font-medium">
									No notes to display.
								</span>
							</div>
						</div>
					</div>
					<div v-if="tab === 'notes'" class="call-info-block">
						<div class="call-info-card">
							<h3
								class="font-nunito font-semibold w-100 text-lg py-1 text-gray-800"
							>
								Call Notes
							</h3>
							<div
								v-for="(note, $index) in page.notes"
								:key="note.id"
								class="flex p-2 font-roboto w-100"
								:class="{
									'border-b': $index + 1 != page.notes.length,
								}"
							>
								<div
									class="flex flex-col items-center justify-center py-2 w-1/4"
								>
									<font-awesome-icon
										class="text-3xl mb-2"
										:icon="['fad', 'user-md-chat']"
										style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
									></font-awesome-icon>
									<span
										class="text-sm text-gray-800 text-center whitespace-no-wrap"
									>
										{{ note.user.first_name }}
										{{ note.user.last_name }}
									</span>
								</div>

								<div class="pl-3 w-4/5 flex flex-col">
									<div
										class="flex items-center w-100 pt-2 px-2 pb-0"
									>
										{{ note.text }}
									</div>

									<div
										class="flex items-center justify-start mt-auto mb-2 pt-2 px-2 pb-0 text-gray-600 text-sm font-medium"
									>
										{{ note.created_at | toDateTime }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="tab === 'history'" class="call-info-block">
						<div class="call-info-card">
							<h3
								class="font-nunito font-semibold w-100 text-lg pt-1 pb-4 text-gray-800"
							>
								Call History
							</h3>
							<div
								v-for="(event, $index) in events"
								:key="event.id"
								class="flex w-100"
								:class="{
									'mb-4': $index + 1 < events.length,
								}"
							>
								<div
									class="flex items-center justify-center px-2 w-16"
								>
									<div class="-mt-5">
										<font-awesome-icon
											v-if="event.type === 'create'"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-alt']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'urgent_forward'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'share']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'archive'"
											class="text-2xl text-purple-600"
											:icon="['far', 'archive']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'update'"
											class="text-2xl text-purple-600"
											:icon="['far', 'user-plus']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'complete'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'check']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'response'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'comment-lines']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'response-call'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-plus']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'dismiss'"
											class="text-2xl text-purple-600"
											:icon="['far', 'bell-slash']"
										/>
										<font-awesome-icon
											v-else-if="event.type === 'note'"
											class="text-2xl text-purple-600"
											:icon="['far', 'clipboard']"
										/>
										<font-awesome-icon
											v-else-if="
												event.type === 'callback'
											"
											class="text-2xl text-purple-600"
											:icon="['far', 'phone-volume']"
										/>
										<font-awesome-icon
											v-else
											class="text-2xl text-purple-600"
											:icon="['far', 'bell']"
										/>
									</div>
								</div>
								<div
									class="flex flex-col justify-between pl-3 w-100"
								>
									<div class="flex items-center px-2 pb-0">
										<span class="text-gray-700">
											{{ event.summary }}
										</span>
									</div>
									<div
										class="flex items-center justify-end pt-1 px-2 pb-0"
									>
										<span class="text-gray-600 text-xs">
											{{ event.created_at | toDateTime }}
										</span>
									</div>
									<hr
										v-if="$index + 1 < page.events.length"
										class="mt-3"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="call-column patient-info">
					<div class="flex flex-col w-100 py-3">
						<div
							class="bg-white border border-solid border-gray-400 rounded shadow-lg flex flex-col relative w-100 break-words"
						>
							<div class="flex flex-col flex-auto p-3">
								<h3
									class="inline-flex items-center font-nunito font-semibold text-lg py-1 text-gray-800 "
								>
									Caller Information
								</h3>
								<div class="mt-3 w-100 text-center">
									<div
										class="flex items-center justify-center text-5xl py-4 px-3 mx-auto w-20 h-20 bg-white rounded-full border-2 border-gray-300"
									>
										<font-awesome-icon
											fixed-width
											:icon="['fad', 'user-alt']"
										/>
									</div>
									<div
										class="flex flex-row justify-between flex-no-wrap mt-2"
									>
										<h4
											class="inline-flex items-center justify-center w-100"
										>
											<span
												v-if="page.patient_name"
												id="patient-name"
												class="font-nunito font-bold w-100 py-1"
											>
												{{ page.patient_name }}
											</span>
											<span
												v-else
												class="font-nunito font-medium text-gray-500 border-b border-dotted py-1 border-gray-400 w-100"
											>
												Patient name
											</span>
										</h4>
									</div>
									<div
										class="flex flex-row justify-between flex-no-wrap mt-2"
									>
										<h4
											class="inline-flex items-center justify-center w-100"
										>
											<span
												contenteditable="false"
												class="font-nunito font-bold w-100 py-1"
											>
												{{ page.patient_dob }}
											</span>
										</h4>
									</div>
									<hr class="mt-2" />
								</div>
								<div
									v-if="page.patient_provider"
									class="mt-3 w-100"
								>
									<h5 class="font-nunito font-semibold mb-3">
										Patient's Provider
									</h5>

									<ul class="font-roboto text-gray-600">
										<li class="mb-4 tracking-wider text-sm">
											<font-awesome-icon
												class="text-2xl"
												:icon="['fad', 'user-md-chat']"
												style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
											></font-awesome-icon>
											<span>
												{{
													page.patient_provider
														.full_name
												}}
											</span>
										</li>
									</ul>
								</div>
								<div
									v-if="page.is_urgent && page.provider"
									class="mt-3 w-100"
								>
									<h5 class="font-nunito font-semibold mb-3">
										Provider Notified
									</h5>

									<ul class="font-roboto text-gray-600">
										<li class="mb-4 tracking-wider text-sm">
											<font-awesome-icon
												class="text-lg mr-2"
												:icon="['fad', 'user-md-chat']"
												style="--fa-primary-color: #6915ef; --fa-secondary-color: #f79421;"
											></font-awesome-icon>
											<span>
												{{ page.provider.full_name }}
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<complete-page-panel
			:show="panels.complete"
			:sid="sid"
			@close="panels.complete = false"
		/>
		<add-note-panel
			:show="panels.notes.create"
			:sid="sid"
			is-page
			@close="panels.notes.create = false"
		/>
		<delete-note-panel
			:show="panels.notes.delete"
			:sid="sid"
			:note="note"
			is-page
			@close="panels.notes.delete = false"
		/>
		<edit-note-panel
			:show="panels.notes.update"
			:sid="sid"
			:note="note"
			is-page
			@close="panels.notes.update = false"
		/>
		<reassign-page-panel
			:show="panels.reassign"
			:page="page"
			@close="panels.reassign = false"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Chip from '@/components/Chip.vue'
import CallTabs from '@/components/CallTabs.vue'
import EditNotePanel from '@/components/Panels/EditNotePanel'
import AddNotePanel from '@/components/Panels/AddNotePanel'
import DeleteNotePanel from '@/components/Panels/DeleteNotePanel'
import CompletePagePanel from '@/components/Panels/CompletePagePanel'
import ReassignPagePanel from '@/components/Panels/ReassignPagePanel'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		AddNotePanel,
		CallTabs,
		CompletePagePanel,
		Chip,
		DeleteNotePanel,
		EditNotePanel,
		ReassignPagePanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current authenticated user's data.
		 *
		 * @return {Object}
		 */
		currentUser() {
			return this.$store.state.auth.user
		},

		/**
		 * Get the patient's DOB from the current page.
		 *
		 * @return {String}
		 */
		dob() {
			return this.page ? this.page.patient_dob : ''
		},

		/**
		 * Get DOB recording.
		 *
		 * @return {Object}
		 */
		dobRecording() {
			if (!this.hasDOBRecording) {
				return
			}

			return this.page.recordings.find(record => record.type === 'dob')
		},

		/**
		 * Get the current call's event messages.
		 *
		 * @return {Array}
		 */
		events() {
			if (!this.page) {
				return []
			}

			return [...this.page.events].sort((a, b) => a.id - b.id)
		},

		/**
		 * Determine if the current call has recordings.
		 *
		 * @return {Boolean}
		 */
		hasRecordings() {
			return this.page.recordings.length > 0
		},

		/**
		 * Determine if the current call has dob recordings.
		 *
		 * @return b
		 */
		hasDOBRecording() {
			if (!this.hasRecordings) {
				return
			}

			return !!this.page.recordings.find(record => record.type === 'dob')
		},

		/**
		 * Determine if the current call has name recordings.
		 *
		 * @return b
		 */
		hasNameRecording() {
			if (!this.hasRecordings) {
				return
			}

			return !!this.page.recordings.find(record => record.type === 'name')
		},

		/**
		 * Determine if the current page is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return !!this.page.completed_at
		},

		/**
		 * Determine if a page is urgent.
		 *
		 * @return {Boolean}
		 */
		isUrgent() {
			return this.page?.is_urgent
		},

		/**
		 * Get name recording.
		 *
		 * @return {Object}
		 */
		nameRecording() {
			if (!this.hasNameRecording) {
				return
			}

			return this.page.recordings.find(record => record.type === 'name')
		},

		/**
		 * Get the current page's notes.
		 *
		 * @return {Array}
		 */
		notes() {
			if (!this.page || !this.page.notes) {
				return []
			}

			return [...this.page.notes].sort((a, b) => a.id - b.id)
		},

		/**
		 * Get the current active page to display.
		 *
		 * @return {Object}
		 */
		page() {
			return this.findBySid(this.$route.params.sid)
		},

		/**
		 * Get the patient's name from the current page.
		 *
		 * @return {String}
		 */
		patientName() {
			return this.page ? this.page.patient_name : ''
		},

		/**
		 * Get the patient's phone number from the current page.
		 *
		 * @return {String}
		 */
		phoneNumber() {
			return this.page ? this.page.callback_number : ''
		},

		/**
		 * Get call's sid.
		 *
		 * @return {String}
		 */
		sid() {
			return this.$route.params.sid
		},

		...mapGetters('pages', ['findBySid']),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Set the current active tab to display.
		 *
		 * @param {String} tab
		 * @return {void}
		 */
		activateTab(tab) {
			this.tab = tab
		},

		/**
		 * Determine if the given note can be edited.
		 *
		 * @param {Object} note
		 * @return {Boolean}
		 */
		canEditNote(note) {
			if (!this.currentUser || !note.user) {
				return false
			}

			return this.currentUser.id === note.user.id
		},

		/**
		 * Export the page to a PDF.
		 *
		 * @return {void}
		 */
		exportToPdf() {
			this.$app.use('App/PDF/CallPDF').make(this.page)
		},

		/**
		 * Fetch the current call's data from the API.
		 *
		 * @param {String} sid
		 * @return {void}
		 */
		async getCall(sid) {
			const partner =
				this.$store.getters.partner?.id ||
				parseInt(this.$route.params.partner)

			try {
				const response = await this.$api
					.partners(partner)
					.calls()
					.find(sid)

				const call = response.get('data', {})

				const partnerIdParam = parseInt(this.$route.params.partner)

				if (call.partner_id !== partnerIdParam) {
					return this.$router.push({
						params: { partner },
						name: 'app.calls',
					})
				}

				await this.$store.dispatch('pages/add', call)
			} catch (e) {
				if (e.status === 404) {
					this.$router.push({
						name: 'app.calls',
						params: { partner },
					})
				}

				this.$alert.response(e)
			}
		},

		/**
		 * Handle the on dismiss page click event.
		 *
		 * @return {void}
		 */
		onDismissPage() {
			this.panels.complete = true
		},

		/**
		 * Handle the on delete note click event.
		 *
		 * @param {Object} call
		 * @param {Object} note
		 * @return {void}
		 */
		onDeleteNoteClick(page, note) {
			this.panels.notes.delete = true

			this.note = { ...note, call_id: page.id }
		},

		/**
		 * Handle the on edit note click event.
		 *
		 * @param {Object} call
		 * @param {Object} note
		 * @return {void}
		 */
		onEditNoteClick(page, note) {
			this.panels.notes.update = true

			this.note = { ...note, call_id: page.id }
		},

		/**
		 * Handle the route/current call change event.
		 *
		 * @return {void}
		 */
		async onRouteChange(to, from) {
			if (
				from &&
				to.params.sid &&
				to.params.partner !== from.params.partner
			) {
				return this.$router.push({
					name: 'app.calls',
					params: { partner: to.params.partner },
				})
			}

			await this.getCall(to.params.sid)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Show',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for changes.
		 */
		$route: {
			immediate: true,
			handler: 'onRouteChange',
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			note: null,
			panels: {
				complete: false,
				notes: {
					create: false,
					delete: false,
					update: false,
				},
				reassign: false,
			},
			tab: 'message',
		}
	},
}
</script>
